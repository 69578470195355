@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';
@import './ChartTooltip.scss';

.doughnut-box {
  border: 0.0714rem solid $grayish-magenta-light;
  border-radius: 0.571rem;
  display: flex;
  align-items: center;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow-y: hidden;

    .header {
      position: relative;
      font-weight: 500;
      font-size: 1.714rem;
    }

    .info-text {
      font-weight: 400;
      font-size: 0.857rem;
      line-height: 1.5rem;
      position: relative;

      .circle {
        display: inline-block;
        margin-right: 0.429rem;
        position: relative;
      }
    }

    .doughnut-wrapper {
      position: relative;
      min-width: 13.714rem;
      max-width: 13.714rem;
      height: 6.6rem;

      .doughnut-canvas {
        position: relative;
        top: -5rem;
      }

      .round-bar {
        z-index: 1;
        position: relative;
        top: -166%;
        left: calc(50% - (3.05336rem / 2));
        width: 3.0536rem;
        height: 3.1336rem;
        border-radius: 50%;
        filter: drop-shadow(0 0.286rem 0.571rem $blue-greyish);
        display: inline-block;

        svg {
          position: relative;
          top: 0.829rem;
          left: 0.8371rem;
          width: 1.429rem;
          height: 1.429rem;
          color: $blue-very-dark-grayish;
        }
      }

      .percentage {
        position: absolute;
        font-weight: 600;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3rem;
        font-size: 0.9rem;

        &.left {
          left: 0.4rem;
        }

        &.right {
          right: 0.4rem;
        }
      }
    }
  }
}
