@import 'assets/styles/_colors.scss';

.ui.grid.draft-column {
  position: relative;
  left: -1.1rem;
  top: -1.1rem;
  &.disabled {
    left: 0;
  }
}

.ui.input.request-input-field {
  font-weight: 400;
  font-size: 0.8571rem;
  line-height: 140%;
  box-sizing: border-box;
  border-radius: 0.429rem;
  height: 2rem;
  font-family: 'Urbanist';
  font-style: normal;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1.2rem;
  input:focus {
    background: $orange-very-light-grayish;
    border: 0.0714rem solid $orange;
    color: $blue-very-dark;
  }
}

.ui.button.btn-request {
  padding: 0.571rem 0.714rem 0.714rem 0.429rem;
  gap: 0.286rem;
  width: 13.429rem;
  height: 2.143rem;
  border-radius: 0.286rem;
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 600;
  font-size: 0.857rem;
  text-align: center;
  text-transform: uppercase;
  &.reject-btn {
    margin-right: 0.7rem;
  }

  &.approve-btn {
    position: relative;
    left: 0.3rem;
  }
}

.btn-row {
  display: flex;
}

.flex-box {
  display: flex;
  .space-left {
    margin-left: 2rem;
  }
}

.doc-boxes {
  border-left: 0.071rem solid $grayish-magenta-light;
  left: 0.4rem;
  width: calc(100% - 0.4rem);
  position: relative;
  .docs-box {
    margin-left: 1rem;
    position: relative;
    top: -1rem;
    .line-dot {
      z-index: 1;
      position: relative;
      left: -1.3rem;
      top: 4.5rem;
      width: 0.429rem;
      height: 0.429rem;
      border-radius: 0.214rem;
      background: $orange;
    }
  }
  &.move-box-down {
    margin-top: 4rem;
  }
  .move-btns-up {
    position: relative;
    top: -4rem;
  }
}

.hidden-line {
  border-left: 0.357rem solid $white;
  height: 5rem;
  position: relative;
  left: -0.1rem;
  top: -4.9rem;
  &.general-open {
    top: -8.1rem;
    width: 2rem;
    display: inline-block;
    height: 8.2rem;
  }
  &.general-closed {
    top: -4.8rem;
    width: 2rem;
    display: inline-block;
  }
}

.inactive-tab {
  width: fit-content;
  padding: 0.4rem;
  margin-bottom: 1.5rem;
  border-radius: 0.4rem;
}
