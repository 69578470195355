@import 'assets/styles/_colors.scss';

.calc-value {
  display: inline;
  padding: 0.4285rem;
  min-width: 0.1rem;
  border-radius: 0.4285rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 0.7857rem;
  line-height: 1.143rem;
  margin-bottom: -2rem;
}
