@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.feedback-box {
  display: inline;
  padding: 0.4285rem;
  min-width: 5.786rem;
  background: $gray-very-light;
  border-radius: 0.4285rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 0.7857rem;
  line-height: 1.143rem;
  color: $blue-very-dark-grayish;

  &.error {
    background: $red-very-pale;
    color: $red-soft;
  }

  &.success {
    background: $green-light-grayish-cyan-lime;
    color: $green;
  }

  &.warning {
    background: $orange-light-grayish;
    color: $orange;
  }
}

.feedback-svg {
  position: relative;
  top: -0.0714rem;
  margin-right: 0.286rem;
}
