@import 'assets/styles/_colors.scss';

.guidance-popup-trigger {
  border: 0.071rem solid $grayish-magenta-light;
  border-radius: 0.571rem;
  margin-bottom: 0.714rem;
  height: 3.143rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
}

.ui.popup.guidance-popup {
  max-width: 42.8571rem;
  max-height: 28.5714rem;
  min-height: 1rem;

  .editor-to-html.guidance-popup-html {
    max-height: 26.7rem;
  }
}
