@import 'assets/styles/_colors.scss';

.revenue-forecast-filter {
  align-items: center;

  .date-column {
    display: flex;
    align-items: center;

    & > span {
      font-size: 0.9rem;
    }

    .date-cell {
      position: relative;
      margin-left: 0.5rem;
    }
  }
}
