@import 'assets/styles/_colors.scss';

.incomplete-fields-box {
  margin-top: 1rem;
  color: white !important;
  .ui.grid.outlined.container {
    background-color: $red-soft;
  }
  .field-list {
    span {
      display: block;
      margin-top: 1rem;
    }
  }
}
