@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.rent-review-and-period-subsection {
  padding: 1.571rem 1.714rem;
  border-radius: 0.571rem;

  .date-range {
    font-style: normal;
    font-weight: 400;
    font-size: 0.9142rem;
    line-height: 140%;
  }

  .top {
    align-items: center;

    .header {
      font-size: 1.1429rem;
      line-height: 1.714rem;
      display: inline;
    }

    .add {
      font-size: 1rem;
      line-height: 1.4285rem;
      border-radius: 0.571rem;
      cursor: default;

      .icon {
        border-radius: 0.643rem;
        justify-content: center;
        align-items: center;
      }

      &:not([disabled]):hover {
        color: $orange;
        background: $orange-light-grayish;
        cursor: pointer;
      }
    }
  }
}
