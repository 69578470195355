@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.page-body-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  > button {
    position: absolute;
    top: 0.1rem;
    width: 0.5rem;
    height: 0.5rem;
    cursor: pointer;
  }

  /*
   * This class control the css of the Silent Saving pop-up
   */
  > .float-saving-status {
    position: absolute;
    left: 0;
    right: 0;
    top: 0.2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      padding: 0.5rem 1rem;
      padding-top: 0.6rem;
      background-color: $orange;
      border-radius: 1rem;
      font-weight: 600;
      color: $white;
      z-index: 100;
      box-shadow: $black-shadow 0.125rem 0.125rem 0.163rem;
      cursor: default;
    }

    @keyframes fadeInAnimation {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    // Message fade after 5s.
    > .message-fadeout {
      animation: fadeInAnimation ease 5s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  // This class is used for hidden button
  > .hidden-float-button {
    position: absolute;
    display: none;

    padding: 1rem;

    top: -2rem;
    right: 1rem;

    background-color: $orange;
    color: $white;
  }
}

.editor-tabs {
  display: flex;
  justify-content: flex-end;
  color: $black;

  .view-text {
    margin-right: 0.4rem;
  }

  & > span {
    display: flex;

    align-items: center;
    justify-content: center;

    padding: 0 0.5rem;
  }

  & > div {
    display: flex;
    flex: 1;
    position: relative;

    align-items: center;
    justify-content: center;

    height: 2.8571rem;
    max-width: 11.4286rem;

    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    background: linear-gradient(90deg, $gray-very-light-v4 0%, $gray-very-light-v4 6%, white 100%);

    &:hover {
      cursor: pointer;
      filter: brightness(0.9);
    }
  }
}

.editor-body {
  display: flex;
  flex: 1;

  .visible {
    visibility: visible;
  }

  .hidden {
    visibility: hidden;
  }

  border-radius: 0.5rem;
  border-top-right-radius: 0;

  background-color: $white;

  & > * {
    width: 100%;
  }

  & ol {
    margin-left: 1rem;
  }

  & li > ol {
    padding-top: 1.5rem;
  }

  & ol > li {
    padding-bottom: 1.5rem;
    position: relative;
  }

  & ol > li::before {
    position: absolute;
    content: '';
    height: calc(100% - 2.2857rem);
    top: 0;
    bottom: 0;
    left: -0.8571rem;
    top: 1.8571rem;
    border-left: 0.1429rem solid $gold-harvest;
  }

  & ol > li {
    position: relative;
    padding-left: 1.25rem;

    &:after {
      content: ' ';
      position: absolute;
      background-color: $gold-harvest;

      top: -0.3571rem;
      left: -1.8571rem;

      height: 2.2143rem;
      width: 2.2143rem;

      border-radius: 50%;
    }
  }

  & li {
    [data-variant='clause'] {
      position: relative;

      padding: 0.25rem 0.5rem;
      padding-right: 2.25rem;

      border-radius: 1rem;
      background-color: $almond-blanched;

      &:hover {
        cursor: pointer;
      }

      &::after {
        content: attr(data-content);

        position: absolute;
        top: 0.2143rem;
        margin-left: 0.3571rem;

        height: 1.5714rem;
        width: 1.5714rem;
        border-radius: 50%;

        font-size: 1.2rem;
        text-align: center;

        background-color: $white;
        border: 0.1429rem solid $gold-harvest;
      }
    }

    [data-variant='clause-abc'] {
      position: relative;

      padding: 0.25rem 0.5rem;
      padding-right: 2.25rem;

      border-radius: 1rem;
      background-color: $almond-blanched;

      &:hover {
        cursor: pointer;
      }

      &::after {
        content: attr(data-content);

        position: absolute;
        top: 0.2143rem;
        margin-left: 0.3571rem;

        height: 1.5714rem;
        width: 1.5714rem;
        border-radius: 50%;

        // Handle the text position inside the Circle
        line-height: 160%;

        font-size: 0.8rem;
        text-align: center;

        background-color: $white;
        border: 0.1429rem solid $gold-harvest;
      }
    }
  }

  & * {
    color: $blue-very-dark;
    font-family: 'Urbanist';
    font-size: 1.25rem;
  }
}

.is-active {
  border-bottom: 0.2857rem solid $orange;
  background: $white;
}
