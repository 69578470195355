@import 'assets/styles/_colors.scss';

/*
 * Note: tabs, segment, secondary, pointing are used in the Tab semantic-ui-react component
 */

.tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .shadow-before {
    position: absolute;
    top: 0;
    height: 2.4rem;
    left: 0;
    width: 1.2rem;
    background: linear-gradient(90deg, rgba(230, 230, 230, 1), transparent);
    opacity: 0;
  }

  .shadow-after {
    position: absolute;
    top: 0;
    height: 2.4rem;
    right: 0;
    width: 1.2rem;
    background: linear-gradient(270deg, rgba(230, 230, 230, 1), transparent);
    opacity: 0;
  }

  .disabled-tab {
    opacity: 0.5;
    pointer-events: none;
  }

  .ui.secondary.pointing.menu {
    display: flex;
    position: relative;
    overflow-x: auto;
    scroll-behavior: smooth;
    color: $gray-dark !important;
    height: 2.5rem !important;
    font-family: Urbanist !important;

    &::-webkit-scrollbar {
      width: 0.1rem;
      height: 0.1rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;

      &:hover {
        background: transparent;
      }
    }

    .item {
      padding-top: 0 !important;
      padding-bottom: 0.714rem !important;
      margin-bottom: 0 !important;
      font-size: 1.1429rem !important;
      color: $gray-dark !important;
      height: 2.5rem !important;

      &.active {
        font-weight: bold !important;
        line-height: 120% !important;
        color: $gray-very-darker !important;
        border-color: $orange;
        padding-top: 0 !important;
        padding-bottom: 0.714rem !important;
        color: $blue-very-dark !important;

        &:hover {
          border-color: $orange;
        }
      }
    }
  }

  .ui.segment {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;

    &.active.tab {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}
