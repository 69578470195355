@import 'assets/styles/_colors.scss';

.conditions-group {
  display: block;
  overflow-x: auto;
  &.disabled * {
    pointer-events: none;
    color: $gray !important;

    svg {
      color: $gray !important;
    }
  }
}

.condition-inner {
  display: flex;
  margin-bottom: 0.6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 44rem;

  .parameter-multi-level-select {
    flex: none;
  }

  .parameter-input {
    width: 14rem;
    max-width: 14rem;
    > div {
      position: relative;
      left: 0.4rem;
      top: 0.1rem;
    }

    > .wrap-icon {
      position: relative;
      left: -0.5rem;
      top: 0.1rem;
    }

    .input-override > input#id-single-input {
      height: 2rem;
    }
  }

  .ui.input.inner-case-1-condition {
    width: 13rem;
    height: fit-content;
    border-radius: 0.429rem;

    &.number-type {
      input {
        border-top-right-radius: inherit !important;
        border-bottom-right-radius: inherit !important;
        border-right-color: $grayish-magenta-light !important;
      }
    }

    &.add-label-unit {
      width: 9.8rem;

      .ui.label {
        background-color: $gray-light;
      }
    }

    &.add-label-percent {
      width: 10.8rem;

      .ui.label {
        background-color: $gray-light;
      }
    }
  }

  .ui.dropdown.mcq-dropdown {
    width: 13rem;
    border-radius: 0.429rem;
  }
}

.ui.input.duration-inputs {
  width: 5.5rem;
  margin-right: 0.3rem;
  border-radius: 0.429rem;
}

.ui.input.default-input {
  width: 13.1rem;
}

.duration-box {
  display: flex;
}

.condition-trash {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.45rem;

  height: 2.5rem;
  width: 2.5rem;
  right: 2.4em;
  cursor: pointer;

  &.group-trash {
    position: relative;
    top: -3rem;
    left: 38rem;
  }

  &.inside-number-types {
    position: absolute;
    right: 3.8rem;
  }

  &:hover {
    filter: brightness(0.9);
  }

  svg {
    color: $red-soft;
    font-size: 1.2rem;
  }
}

.ui.fluid.dropdown.dropdown-operator {
  width: min-content;
  min-width: 7rem;
  white-space: nowrap;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 0.429rem;
  border: 0.071rem solid $grayish-magenta-light;
  height: auto;

  > .text {
    position: relative;
    top: 0.05rem;
  }

  > div {
    top: 3rem;
  }

  > i {
    top: 0.7rem !important;
  }

  &.move-operators {
    > .text {
      top: 0.14rem;
    }

    > i {
      top: 0.8rem !important;
    }
  }
}

.condition-show-ground {
  position: relative;
  // padding: 1.5rem 1rem 3rem 0.2rem;
  // margin: -2.5rem 1rem 3rem;
  border-radius: 0.5rem;
  width: fit-content;

  // &:after {
  //   content: ')';
  //   background-color: $white;
  //   position: absolute;
  //   color: $black;
  //   bottom: -2rem;
  //   right: 3rem;
  //   font-size: 1.5rem;
  // }
}

.add-row {
  justify-content: flex-end;

  & > span {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    position: relative;
    left: -5.6rem;

    &:hover {
      cursor: pointer;
      filter: brightness(0.9);
    }

    svg {
      color: $orange;
      font-size: 2.6rem;
    }
  }
}

.ui.dropdown.dropdown-prefix-logic {
  min-width: 5rem;
  width: min-content;
  // left: 1rem;
  // top: -0.5rem;
  border-radius: 0.429rem;
  border: 0.071rem solid $grayish-magenta-light;
}
