@import 'assets/styles/_colors.scss';

.more-reports-btn {
  align-items: center;
  justify-content: center;

  .ui.button.more-reports {
    border-radius: 0.286rem;
    font-family: 'Urbanist';
    font-style: normal;
    font-size: 0.857rem;
    line-height: 1.357rem;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    width: 14rem;

    svg {
      position: relative;
      bottom: 0.1rem;
    }
  }
}
