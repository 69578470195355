@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.total-box {
  border: 0.0714rem solid $grayish-magenta-light;
  border-radius: 0.571rem;
  display: flex;
  flex-direction: column;

  .header {
    position: relative;
    font-weight: 500;
    font-size: 1.714rem;
  }

  .sub-header {
    font-weight: 400;
    font-size: 0.857rem;
  }

  // color of the vertical separator in the middle of the Totalbox
  .border-left {
    border-left: 0.035rem solid $gray-very-light-v6;
  }

  .border-right {
    border-right: 0.035rem solid $gray-very-light-v6;
  }

  // define the height of the separator in the middle of the Totalbox
  .remaining-height {
    flex-grow: 1;
  }

  .column {
    flex-direction: column;
  }

  .total-row {
    display: flex;
    justify-content: space-between;

    .main-count {
      font-weight: 500;
      font-size: 1.714rem;
    }

    .sub-count {
      font-size: 0.85rem;
      font-weight: 600 !important;
      align-self: end;

      svg {
        position: relative;
        top: 0.07rem;
      }
    }
  }
}
