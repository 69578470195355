@import 'assets/styles/_colors.scss';

.custom-accordion {
  margin-bottom: 1rem;
  border-radius: 0.7rem;
  border: 0.1rem solid $gray-light;
  & > .custom-accordion-content {
    padding-left: 0.7rem !important;
    padding-right: 0.7rem !important;
    padding-top: 0.2rem !important;
    padding-bottom: 0rem !important;
    .row {
      padding: 0;
    }
    .note {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
    }
  }
  .clock-icon {
    border-radius: 0.286rem;
    font-size: 1.5rem;
    position: relative;
    top: 0.4rem;
  }

  .inside-content {
    display: flex;
    flex-direction: column;
    &.image-type {
      display: block;
    }
    .default-policy {
      position: relative;
    }
  }
  .valuation-alert {
    position: relative;
    top: -0.3rem;
    color: $red-soft;
    font-weight: 600;
    .caution-icon {
      position: relative;
      top: 0.4rem;
      font-size: 1.5rem;
    }
  }
}

.custom-accordion-title {
  border-top-right-radius: 0.7rem !important;
  border-top-left-radius: 0.7rem !important;
  padding: 0.7rem !important;

  &.active {
    .open-icon {
      transform: rotate(90deg);
    }
  }
}
.open-icon {
  float: right;
  transition:
    transform 0.1s ease,
    opacity 0.1s ease;
}
.custom-accordion-menu {
  margin-bottom: 1rem !important;
}

.custom-accordion-icon-drop-down-duidance {
  margin: auto 0 auto 0.5rem;
}

.column .custom-accordion-drop-down-guidance {
  margin-top: -1.714rem !important;
  top: 0.714rem;
  margin-bottom: 0.3rem !important;
}

.column .preview-guidance {
  margin-bottom: 0.5rem !important;
}

.trash-box {
  width: 1.72rem;
  height: 2rem;
  display: inline-block;
  position: relative;
  top: 0.2rem;
  left: 0.8rem;
  border-radius: 0.286rem;
  .clear-answer-history {
    font-size: 1.5rem;
    position: relative;
    left: 0.1rem;
    top: 0.25rem;
  }
}

.trigger-open {
  display: block;
}
