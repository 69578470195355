@import 'assets/styles/_colors.scss';

.ui.button.create-tenant {
  min-width: 6rem;
  height: 2.143rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.286rem;
  font-weight: 500;
  font-size: 0.8571rem;
  line-height: 120%;
  text-transform: uppercase;
}
