@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.progress-bar-container {
  display: flex;
  flex-direction: column;
  width: 11rem;
}

.statistics {
  display: flex;
  flex-direction: row;
  text-overflow: ellipsis;
}

.progress-template {
  display: flex;
  position: relative;
  border-radius: 0.25rem;
  height: 0.25rem;
  background: linear-gradient(180deg, $orange-light 0%, $orange 100%);
}

.progress-template-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 0.25rem;
}

@media (min-width: $big-monitors-min-width) {
  .card-parameters .progress-bar-container {
    width: 105% !important;
  }
}
