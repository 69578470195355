@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.review-and-period {
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.4285rem;
    color: $blue-desaturated-dark;
    margin-top: 2.3rem;
    margin-left: auto;
    display: flex;
    justify-content: center;

    &.no-mt {
      margin-top: 0;
    }
  }

  .period-delete {
    position: absolute;
    right: 0;
    top: 5rem;
    z-index: 2;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.4285rem;
    color: $blue-very-dark-grayish;
    color: $red-soft;
    display: flex;
    border-radius: 0.571rem;
    cursor: default;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.271rem;

      svg {
        color: $red;
      }
    }

    &:hover {
      background: $red-very-pale;
      cursor: pointer;
    }
  }
}

.ui.secondary.pointing.menu .item.error-tab {
  color: $red-soft !important;
}

.ui.secondary.pointing.menu .active.item.error-tab {
  color: $red-soft !important;
}
