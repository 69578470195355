@import 'assets/styles/_colors.scss';

.ui.modal.modal-template.parameters-group-questions-modal {
  width: 60vw;
  top: 6rem;
  .save-btn {
    right: 8rem;
  }
}

.parameters-group-questions-form {
  .legend-header {
    display: flex;
    justify-content: center;
    height: 3rem;
    margin-bottom: 1rem;

    .legend-title {
      float: left;
      display: inline;
      font-size: 1.8rem;
      margin: 0 1.5rem;
      padding: 0;
    }
    .legend-parameters-defined {
      float: left;
      margin-right: 1rem;
      padding-top: 0.5rem;
      position: relative;
      top: 0.2rem;
    }

    .legend {
      list-style: none;
      display: inline;
      position: relative;
      top: 0.1em;
      li {
        float: left;
        margin-right: 3rem;
        padding-top: 0.4rem;
      }
      span {
        float: left;
        width: 2.2rem;
        height: 1.2rem;
        margin: 0.2rem;
      }
    }

    .icon-reset {
      padding-top: 0.5rem;
      width: 1rem;
    }

    .back-button {
      cursor: pointer;
      background-color: $orange-very-light-grayish;
      float: left;
      .icon-back-button {
        margin-right: 0.5rem;
      }
    }
  }

  .reset-all-column {
    text-align: right;
    color: $red-soft;
    padding-right: 0.5rem;
  }

  .reset-all-button {
    cursor: pointer;
  }
  .ui.button.update-parameter-policy {
    float: right;
    margin-top: 0.5rem;
  }
  .parameter-header-content {
    position: relative;
    top: 0.2rem;
  }
  .question-tab {
    position: relative;
  }
}
