@import 'assets/styles/_colors.scss';

.setup-accordian {
  width: 100% !important;
  padding: 1.714rem;
  background: white;
  box-sizing: border-box;
  border-radius: 0.8571rem;

  .setup-title-accordion {
    display: flex;
    justify-content: space-between;

    .setup-title {
      font-family: 'Urbanist';
      font-style: normal;
      font-weight: 600;
      font-size: 1.286rem;
      margin-bottom: 0;
      display: flex;
    }

    .setup-icon {
      font-size: 1.5rem !important;
    }
  }

  .accordion-content {
    & > :first-child {
      & > :first-child {
        padding-bottom: 0;
      }
    }
  }
}
