@import 'assets/styles/_colors.scss';

.graph-tooltip-container {
  .inner-html.revenue-forecast {
    max-width: 22.5714rem;

    .title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.4285rem;
      padding-bottom: 0.5rem;
      border-bottom: 0.071rem solid $gray-light;
    }

    p {
      font-weight: 400;
      font-size: 0.857rem;
      line-height: 140%;
      padding: 0;
      display: flex;
      align-items: center;

      span.circle {
        height: 0.429rem;
        width: 0.429rem;
        border-radius: 0.6429rem;
        margin-right: 0.286rem;
      }
    }

    .alert,
    .event {
      border-radius: 0.428rem;
      padding: 0.3rem;
      font-style: normal;
      font-weight: bolder;
      font-size: 0.785rem;
      line-height: 120%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      margin-bottom: 0.3rem;

      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }
    }

    .alert {
      background: $orange-light-grayish;
      color: $orange;
    }

    .event {
      background: $blue-light-grayish;
      color: $blue-royal;
    }
  }
}
