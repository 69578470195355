@import 'assets/styles/_colors.scss';
$event-point-size: 1.1428rem;

.outlined-container-timeline {
  margin: 0;
  padding: 2rem;
  padding-right: 4rem;
  box-sizing: border-box;
  border-radius: 0.8571rem;

  .ui.active.loader {
    &::after {
      border-color: $orange transparent transparent;
    }
  }

  .timeline-wrapper {
    position: relative;
    min-width: 100%;
    height: inherit;
    min-height: 20rem;

    .timeline-container {
      position: absolute;
      top: 49.5%;
      height: 0.0714rem;
      width: 94%;

      .timeline-x-axis-line {
        position: absolute;
        width: 106%;
        height: 100%;

        &::after {
          content: '';
          border: solid $gray;
          border-width: 0 0.0714rem 0.0714rem 0;
          display: inline-block;
          padding: 0.2142rem;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          position: absolute;
          right: 0;
          top: -0.21rem;
          z-index: 1;
        }
      }

      .time-event-description {
        position: absolute;
        top: -#{calc($event-point-size * 5)};
        transform: rotate(-45deg);
        display: flex;
        align-items: center;
      }

      .timeline-event-text {
        min-width: 2rem;
        min-height: 2rem;
        padding: 0.4285rem;
        border-radius: 0.4285rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 0.7142rem;
        line-height: 140%;
        text-transform: uppercase;

        &.timeline-event-date {
          position: absolute;
          z-index: 2;
        }

        &.timeline-event-type {
          font-size: 0.8571rem;
          min-width: 6rem;
        }
      }

      .timeline-event-point {
        position: absolute;
        top: -#{calc($event-point-size / 2)};
        width: $event-point-size;
        height: $event-point-size;
        border-radius: calc($event-point-size / 2);
        z-index: 4;
      }

      .timeline-event-period {
        position: absolute;
        border-top: 0.0714rem solid $blue-very-dark-grayish;
        z-index: 6;
        display: flex;
        justify-content: center;

        &::before {
          content: '';
          border: solid $blue-very-dark-grayish;
          border-width: 0 0.0714rem 0.0714rem 0;
          display: inline-block;
          padding: 0.2142rem;
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
          position: absolute;
          left: 0;
          top: -0.2857rem;
          z-index: 6;
        }

        &::after {
          content: '';
          border: solid $blue-very-dark-grayish;
          border-width: 0 0.0714rem 0.0714rem 0;
          display: inline-block;
          padding: 0.2142rem;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          position: absolute;
          right: 0;
          top: -0.2857rem;
          z-index: 6;
        }

        svg {
          z-index: 2;
          background: $white;
        }
      }

      .timeline-today-box {
        position: absolute;
        transform: translateX(-2.18rem);
        z-index: 1;

        .timeline-today-text {
          padding: 0.4285rem;
          border: 0.0714rem solid $blue-very-dark;
          box-shadow: 0 0.2857rem 1.1428rem $blue-greyish;
          border-radius: 0.2857rem;
          z-index: 5;
          text-align: center;
          font-weight: 600;
          font-size: 0.7142rem;
          line-height: 140%;

          span {
            display: block;
          }
        }

        .timeline-today-pole {
          border-left: 0.0714rem solid $blue-very-dark;
          position: absolute;
          right: 50%;
        }
      }
    }

    .green {
      background: $green-light-grayish-cyan-lime;
      color: $green;

      &.bg {
        background: $green;
      }
    }

    .orange {
      background: $orange-light-grayish;
      color: $orange;

      &.bg {
        background: $orange;
      }
    }

    .purple {
      background: $pink-soft;
      color: $purple;

      &.bg {
        background: $purple;
      }
    }

    .blue {
      background: $blue-light-grayish;
      color: $blue-royal;

      &.bg {
        background: $blue-royal;
      }
    }

    .red {
      background: $red-very-pale;
      color: $red-soft;

      &.bg {
        background: $red-soft;
      }
    }

    .tomato {
      background: $red-very-pale;
      color: $red-tomato;

      &.bg {
        background: $red-tomato;
      }
    }
  }
}
