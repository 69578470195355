@import 'assets/styles/_colors.scss';
$first-column-width: 7.5rem;
$border-color: rgba(34, 36, 38, 0.15);
$left: 30rem;

.comparables-table-container {
  position: relative;
  border: 0.07142rem solid $border-color;
  border-radius: 0.2857rem;
  overflow: hidden;

  .shadow-right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    border-radius: 0.2857rem;
  }

  .scrollable-wrapper {
    position: relative;
    overflow-x: auto;
  }

  .benchmark-header-name {
    color: inherit;
  }
  .benchmark-header-name:hover {
    text-decoration: underline;
  }

  .row-sub-header {
    div {
      width: 100%;
      display: inline-flex;

      span:nth-child(1) {
        width: 80%;
      }
      span:nth-child(2) {
        width: 20%;
        float: right;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    svg {
      font-size: 1.7rem;
      padding: 0.15rem;
    }

    .action-icons {
      .filter-icon,
      .sort-icon {
        border-radius: 0.2857rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: auto;

        &:hover,
        &.active {
          background-color: $white;
          box-shadow:
            0 0.2857rem 0.4285rem $black-shadow,
            0 0 0.8571rem $black-shadow;
        }

        &.active {
          color: $orange;
        }
      }

      .sort-icon {
        svg {
          transform: translateX(-0.2142rem);
        }
      }
    }
  }

  .comparables-table {
    border: none !important;
    thead {
      th {
        position: sticky;
        top: 0;
        z-index: 3;
        padding: 0 0.5rem;
        background-color: $white-smoke;

        &:nth-of-type(1) {
          min-width: 30rem;
          width: 30rem;
          z-index: 4;
          left: 0;
        }

        &:nth-of-type(2) {
          position: sticky;
          z-index: 4;
          left: $left;
          border-right: 0.07142rem solid $border-color;
        }

        span {
          display: inline-flex;
        }

        .filter-icon {
          font-size: 1.7rem;
          display: block;
          cursor: pointer;
        }

        .column-cancel {
          height: 1.4rem;
          width: 1.4rem;
          border-radius: 0.7rem;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            box-shadow: 0 0 0 0.2rem $red-soft;
          }

          svg {
            color: $red-soft;
            transform: scale(1.3);
            cursor: pointer;
          }
        }

        .move-icons {
          display: flex;
          font-size: 1.2rem;

          .arrow {
            cursor: pointer;
            border-radius: 50%;

            &:hover {
              background: $gray-light-v2;
            }
          }
        }
      }
    }

    tbody {
      tr {
        td {
          min-width: 12rem;
          padding: 0.5rem 1rem;

          &:nth-of-type(1) {
            min-width: 30rem;
            width: 30rem;

            position: sticky;
            left: 0;
            background-color: $white; // to keep the column top of the scrolling
            z-index: 2;
          }

          &:nth-of-type(2) {
            min-width: 12rem;
            position: sticky;
            left: $left;
            z-index: 2;
            background-color: $white; // to keep the column top of the scrolling
            border-right: 0.07142rem solid $border-color;
          }
        }
      }
    }

    .row-header {
      td {
        background-color: $white-smoke !important;
      }
    }

    .row-filter-icon {
      cursor: pointer;
      vertical-align: middle;
    }
  }
}

.ui.modal.valuation-contract-view-filters-modal {
  
  .save-btn {
    right: 19rem !important;
    width: 10rem !important;
  }
  .reset-btn {
    right: 8rem !important;
    width: 10rem !important;
  }
  .contract-view-valuation-tab-form {
    .label {
      font-weight: 700;
    }
    .d-grid {
      display: grid;
    }
    .range-filter {
      .field {
        width: 48%;
      }
      .field:nth-child(1) {
        margin-right: auto;
      }
    }
    .radio-filter {
      .field {
        width: 20%;
      }
    }

    .surrender-filter .field {
      padding: 0.67857143em 1em;
    }
    .building-grade-filter {
      .p-multiselect .p-multiselect-label {
        padding: 0.67857143em 1em !important ;
      }
    }
  }
}

.ui.modal.valuation-contract-view-row-filters-modal {
  .filter-box{
    display: flex !important;
    height: 220px;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
  }
  width: 40rem;
  .dropdown-select-wrapper {
    width: 100%;
  }
  .content{
    height: 265px;
  }
}
