@import 'assets/styles/_colors.scss';

.position-left-rent-title {
  position: absolute;
  left: -5.4285rem;
  top: 2.4285rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.4285rem;
}
