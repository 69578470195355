@import 'assets/styles/_colors.scss';

.premises-sub-section {
  .sub-section {
    margin-left: 0;
    margin-right: 0;

    .row {
      &[spaces='2'] {
        padding-bottom: 2.857rem !important;
      }

      &[spaces='3'] {
        padding-bottom: 4.143rem !important;
      }

      &[spaces='4'] {
        padding-bottom: 6rem !important;
      }

      &[spaces='5'] {
        padding-bottom: 7.429rem !important;
      }

      &[spaces='6'] {
        padding-bottom: 9.571rem !important;
      }

      &.row:not(:last-of-type) {
        position: relative;

        .sn-container {
          width: 2.571rem !important;
          display: flex;
          align-items: center;
          padding: 0;

          .sn {
            margin: 0 !important;
            width: 100% !important;

            &.first-row {
              position: relative;
              top: 0.7rem;
            }

            div.required {
              position: relative;
              top: 0.0714rem;
              display: inline;
              margin-left: 0.286rem;
            }

            &.blur {
              color: $blue-desaturated-dark !important;
            }
          }

          .v-center {
            margin-top: 1.643rem !important;
          }
        }

        .delete-btn {
          position: absolute;
          right: 0;
          border-radius: 0.429rem;
          justify-content: center;
          align-items: center;
          top: 37%;
          cursor: pointer;

          svg {
            transform: scale(1.3);
          }
        }
      }
    }

    .area {
      display: flex !important;
      align-items: center;

      &.first-row {
        position: relative;
        top: 0.7rem;
      }

      .area-value {
        margin-top: 0.071rem;
      }

      .bold {
        &.min-width {
          min-width: 3.7143rem;
        }

        font-style: normal;
        font-weight: 700;
        font-size: 0.8571rem;
        line-height: 1rem;
        color: $blue-very-dark;
        margin-right: 0.286rem;
      }
    }

    .total-row {
      margin-top: 0.5rem;
      border-top: 0.0714rem solid $grayish-magenta-light;
    }
  }

  .ui.button.add-floor-container {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.286rem;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 0.8571rem;
      line-height: 1.357rem;
      text-align: center;
      text-transform: uppercase;
      top: 0.08rem;
      position: relative;
    }

    svg {
      position: relative;
      top: -0.05rem;
    }
  }
}
