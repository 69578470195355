@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

/* **********************************************************
 * Definition of the classes for the cursors
 * **********************************************************/
.cursor-pointer {
  cursor: pointer !important;
}

/* **********************************************************
 * Definition of the classes for the borders
 * **********************************************************/
.border-none {
  border: none !important;
}

.border-top {
  border-top: solid 0.0714rem $gray-lighter !important;
}

.border-sm-orange {
  border: 0.0714rem solid $orange !important;
}

.border-sm-grayish-magenta-light {
  border: 0.0714rem solid $grayish-magenta-light !important;
}

.border-sm-gray-light {
  border: 0.0714rem solid $gray-lighter !important;
}

.border-sm-blue-very-dark {
  border: 0.0714rem solid $blue-very-dark-grayish !important;
}

.border-sm-green {
  border: 0.0714rem solid $green !important;
}

.border-sm-red-soft {
  border: 0.0714rem solid $red-soft !important;
}

.border-sm-gray-very-light-v6 {
  border: 0.0714rem solid $gray-very-light-v6 !important;
}

.border-sm-red-light-soft {
  border: 0.0714rem solid $red-light-soft !important;
}

.border-sm-blue-desaturated-dark {
  border: 0.0714rem solid $blue-desaturated-dark !important;
}

.border-sm-gray-very-light {
  border: 0.0714rem solid $gray-very-light !important;
}

// TODO : check if can be merged with border-sm-gray-very-light
.border-sm-gray-light-v2 {
  border: 0.0714rem solid $gray-light-v2 !important;
}

.border-ml-orange {
  border: 0.107rem solid $orange !important;
}

.border-ml-blue-very-dark {
  border: 0.107rem solid $blue-very-dark-grayish !important;
}

.border-ml-red-soft {
  border: 0.107rem solid $red-soft !important;
}

.border-xl-orange {
  border: 0.1429rem solid $orange !important;
}

.border-xl-red-soft {
  border: 0.1429rem solid $red-soft !important;
}

.border-xl-green {
  border: 0.1429rem solid $green !important;
}

.border-xl-gray-very-light {
  border: 0.1429rem solid $gray-very-light !important;
}

.border-bottom-sm-grayish-magenta-light {
  border-bottom: 0.0714rem solid $grayish-magenta-light !important;
}

/* **********************************************************
 * Definition of the classes for the required icons
 * **********************************************************/

// require indicator for the section titles
.required-indicator-title {
  color: $orange;
  font-weight: 700;
  font-size: 1.8rem;
  margin-left: 0.214rem;
}

// require indicator for the labels
label {
  .required-indicator {
    color: $orange;
    position: relative;
    font-weight: 700;
    font-size: 1.286rem;
    top: 0.0714rem;
    margin-left: 0.2rem;
  }
}

.required {
  color: $orange;
  font-weight: 700;
  font-size: 1.286rem;
  position: relative;
  top: 0.286rem;
  left: -0.0714rem;
}

// require indicator for the choices
.required-indicator-choices {
  color: $orange;
  position: relative;
  top: -0.143rem;
}

/* **********************************************************
 * Definition of the classes for the display
 * **********************************************************/
.d-grid {
  display: grid !important;
}

.d-flex {
  display: flex !important;
}

.d-flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-inline-table {
  display: inline-table !important;
}

.d-contents {
  display: contents;
}

/* **********************************************************
 * Definition of the classes for the alignments
 * **********************************************************/
.position-relative {
  position: relative !important;
}

.text-center {
  text-align: center !important;
}

.align-center {
  align-items: center !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: end;
}

.justify-space-between {
  justify-content: space-between !important;
}

/* **********************************************************
 * Definition of the classes for gap when display: flex is used
 * **********************************************************/

.flex-gap-xxs {
  gap: 0.25rem !important;
}

.flex-gap-xs {
  gap: 0.5rem !important;
}

.flex-gap-s {
  gap: 0.75rem !important;
}

.flex-gap-sm {
  gap: 1rem !important;
}

.flex-gap-m {
  gap: 1.25rem !important;
}

.flex-gap-ml {
  gap: 1.5rem !important;
}

.flex-gap-l {
  gap: 1.75rem !important;
}

.flex-gap-xl {
  gap: 2rem !important;
}

.flex-gap-xxl {
  gap: 3rem !important;
}

/* class used to disable the input, text area, date picker, dropdown etc... fields */
.custom-disabled {
  pointer-events: none;
  background: $gray-lighter !important;

  &.input-field {
    border-radius: 0.429rem;
  }

  input {
    background: $gray-lighter !important;
  }

  i,
  .arrow {
    display: none;
  }
}

/**
  * Element Checkbox from semantic-ui-react library css
  * 
 */
.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  color: $orange;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: $orange !important;
}

.ui.radio.checkbox input:checked ~ .box:after,
.ui.radio.checkbox input:checked ~ label:after {
  background-color: $orange;
}

.ui.radio.checkbox {
  margin-top: 0.714rem;

  label {
    color: $blue-very-dark;
  }
}

.ui.disabled.radio.checkbox input:checked ~ {
  label::after {
    background-color: $orange-soft;
  }

  label {
    opacity: 1;
  }
}

/* **********************************************************
 * TODO below needs to be cleaned
 * **********************************************************/
.field.field-style {
  .pos-rel {
    position: relative;
    min-height: 2.714rem;
    width: 100%;
  }

  .input-validation-message {
    position: absolute;
    font-weight: 600;
    font-size: 0.714rem;
    line-height: 140%;
    padding-left: 0.286rem;

    svg {
      position: relative;
      top: 0.1rem;
    }

    &.error {
      color: $red-soft !important;
    }

    &.success {
      align-items: center;
    }
  }

  label {
    font-size: 0.8571rem !important;
    color: $blue-very-dark !important;
    padding-bottom: 0.429rem;
    position: relative;
    width: 100%;
    display: flex;

    > span:first-child {
      display: inline-block;
      max-width: 84%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      bottom: -0.6rem;
      position: relative;
    }
  }

  .ui.input {
    width: 100%;
  }

  input {
    font-weight: 400 !important;
    font-size: 0.8571rem !important;
    line-height: 140% !important;
    border: 0.0714rem solid $grayish-magenta-light !important;
    color: $blue-very-dark !important;
    box-sizing: border-box;
    border-radius: 0.429rem;
    height: 2.714rem;
    font-family: 'Urbanist' !important;
    font-style: normal;
    display: flex;
    align-items: center;

    &:focus {
      background: $orange-very-light-grayish !important;
      border: 0.0714rem solid $orange !important;
    }
  }

  textarea {
    height: 9.14rem;
    resize: none;
    color: $blue-very-dark !important;
    font-weight: 400 !important;
    font-size: 0.8571rem !important;
    font-family: 'Urbanist' !important;
    border-radius: 0.429rem !important;

    &[readonly] {
      pointer-events: all;
      background: $gray-lighter !important;

      &:focus {
        background: $gray-lighter !important;
        border: 0.0714rem solid $grayish-magenta-light !important;
      }
    }

    &:focus {
      background: $orange-very-light-grayish !important;
      border: 0.0714rem solid $orange !important;
    }
  }

  // Error Field css for input, text area
  &.error-field,
  .error-field {
    border: 0.0714rem solid $red-soft !important;

    // For Select
    &.ui.selection.dropdown {
      border: 0.0714rem solid $red-soft !important;

      input {
        border: none !important;
      }
    }

    &:focus {
      background-color: $red-very-pale !important;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ui.button.btn {
  &.grey-outline {
    border: 0.0714rem solid $blue-very-dark-grayish;
    color: $blue-very-dark-grayish;
    background: $white;
  }

  &.grey-bg {
    border: none;
    color: $white;
    background: $blue-very-dark-grayish;
  }

  &.red-outline {
    border: 0.0714rem solid $red-soft;
    color: $red-soft;
    background: $white;
  }

  &.red-bg {
    border: none;
    color: $white;
    background: $red-soft;
  }

  &.green-outline {
    border: 0.0714rem solid $green;
    color: $green;
    background: $white;
  }

  &.green-bg {
    border: none;
    color: $white;
    background: $green;
  }

  &.orange-outline {
    border: 0.0714rem solid $orange;
    color: $orange;
    background: $white;
  }

  &.orange-bg {
    border: none;
    color: $white;
    background: $orange;
  }

  &.black-outline {
    border: 0.0714rem solid $black;
    color: $black;
    background: $white;
  }

  &.black-bg {
    border: none;
    color: $white;
    background: $black;
  }
}

.info-icon {
  color: $orange;
  margin-left: 0.286rem;
  position: relative;
  top: 0.1429rem;
  width: 1.1429rem;
  height: 1.1429rem;
}

.input-field {
  ::placeholder {
    color: $blue-dark-grayish !important;
  }
}

/* **********************************************************
 * Definition of the classes related to the font weights
 * **********************************************************/
.font-500 {
  font-weight: 500 !important;
}

.font-600 {
  font-weight: 600 !important;
}

/* **********************************************************
 * Definition of the classes related to the font size
 * **********************************************************/
.font-size-0_8571 {
  font-size: 0.8571rem !important;
}

.font-size-1 {
  font-size: 1rem !important;
}

.font-size-1_1429 {
  font-size: 1.1429rem !important;
}

/* **********************************************************
 * Definition of the classes related to the font size
 * **********************************************************/

.font-orange {
  color: $orange;
}

/* **********************************************************
 * Definition of the classes related to the Line Heights
 * **********************************************************/
.line-100 {
  line-height: 100% !important;
}

.line-120 {
  line-height: 120% !important;
}

/* **********************************************************
 * Definition of the classes related to the circles
 * **********************************************************/
.circle-xs {
  width: 0.429rem;
  height: 0.429rem;
  border-radius: 50%;
}

.circle-sm {
  width: 0.571rem;
  height: 0.571rem;
  border-radius: 50%;
}

.circle-m {
  height: 1.286rem;
  width: 1.286rem;
  border-radius: 50%;
}

.circle-l {
  height: 1.857rem;
  width: 1.857rem;
  border-radius: 50%;
}

.circle-xl {
  width: 2.429rem;
  height: 2.429rem;
  border-radius: 50%;
}

.plus-icon {
  background: $orange;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    color: white;
  }
}

/* **********************************************************
 * Definition of the classes for the backgrounds
 * **********************************************************/

.bg-white {
  background: $white !important;
}

.bg-transparent {
  background: transparent;
}

.bg-blue {
  background: $blue !important;
}

.bg-blue-dark-grayish {
  background: $blue-dark-grayish !important;
}

.bg-blue-very-dark-grayish {
  background: $blue-very-dark-grayish !important;
}

.bg-blue-light-grayish {
  background: $blue-light-grayish;
}

.bg-blue-saturated-dark {
  background: $blue-saturated-dark;
}

.bg-purple {
  background: $purple;
}

.bg-yellow {
  background: $yellow !important;
}

.bg-green {
  background: $green !important;
}

.bg-green-lime {
  background: $green-lime !important;
}

.bg-green-pale-lime {
  background: $green-pale-lime !important;
}

.bg-orange {
  background: $orange !important;
}

.bg-orange-very-light-grayish {
  background: $orange-very-light-grayish !important;
}

.bg-orange-light-grayish {
  background: $orange-light-grayish;
}

.bg-gray {
  background: $gray !important;
}

.bg-gray-light {
  background: $gray-light;
}

.bg-gray-lighter {
  background: $gray-lighter;
}

.bg-gray-very-light {
  background: $gray-very-light !important;
}

.bg-gray-very-light-v3 {
  background: $gray-very-light-v3 !important;
}

.bg-gray-very-light-v4 {
  background: $gray-very-light-v4 !important;
}

.bg-grayish-magenta-light {
  background: $grayish-magenta-light !important;
}

.bg-gray-blackish {
  background: $gray-blackish !important;
}

.bg-gray-whitish-light {
  background: $gray-whitish-light !important;
}

.bg-green-light-grayish-cyan-lime {
  background: $green-light-grayish-cyan-lime;
}

.bg-red {
  background: $red !important;
}

.bg-red-soft {
  background: $red-soft !important;
}

.bg-red-light {
  background: $red-light !important;
}

.bg-red-light-soft {
  background: $red-light-soft;
}

.bg-red-very-pale {
  background: $red-very-pale;
}

.bg-pink-bright {
  background: $pink-bright;
}

/* **********************************************************
 * Definition of the classes for the Colors
 * **********************************************************/
.color-white {
  color: $white !important;
}

.color-black {
  color: $black !important;
}

.color-gray {
  color: $gray !important;
}

.color-gray-dark {
  color: $gray-dark;
}

.color-gray-very-dark {
  color: $gray-very-dark !important;
}

.color-gray-very-darker {
  color: $gray-very-darker !important;
}

.color-gray-whitish-light {
  color: $gray-whitish-light !important;
}

.color-blue {
  color: $blue !important;
}

.color-blue-dark {
  color: $blue-dark !important;
}

.color-blue-very-dark {
  color: $blue-very-dark !important;
}

.color-blue-very-dark-grayish {
  color: $blue-very-dark-grayish !important;
}

.color-blue-dark-grayish {
  color: $blue-dark-grayish !important;
}

.color-blue-desaturated-dark {
  color: $blue-desaturated-dark;
}

.color-orange {
  color: $orange !important;
}

.color-red-soft {
  color: $red-soft !important;
}

.color-red-very-dark-grayish {
  color: $red-very-dark-grayish;
}

.color-green {
  color: $green;
}

/* **********************************************************
 * Definition of the classes for the Heights
 * **********************************************************/
.h-100 {
  height: 100% !important;
}

.height-0_0714 {
  height: 0.0714rem;
}

.height-0_857 {
  height: 0.8571rem;
}

.height-1_143 {
  height: 1.143rem;
}

.height-1_286 {
  height: 1.286rem;
}

.height-1_6 {
  height: 1.6rem !important;
}

.height-2_429 {
  height: 2.429rem !important;
}

.height-2_143 {
  height: 2.143rem !important;
}

.height-2_857 {
  height: 2.857rem !important;
}

/* **********************************************************
 * Definition of the classes for the Widths
 * **********************************************************/
.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.width-auto {
  width: auto !important;
}

.width-0_857 {
  width: 0.8571rem !important;
}

.width-1_143 {
  width: 1.143rem;
}

.width-1_286 {
  width: 1.286rem;
}

.width-1_6 {
  width: 1.6rem !important;
}

.width-2_429 {
  width: 2.429rem !important;
}

.width-2_857 {
  width: 2.857rem !important;
}

.width-5_714 {
  width: 5.714rem;
}

.width-11_429 {
  width: 11.429rem;
}

.width-13_429 {
  width: 13.429rem;
}
