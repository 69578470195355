@import 'assets/styles/_colors.scss';

.contract-page {
  .details-top-btn {
    display: flex;
    justify-content: flex-end;
  }

  .column-width {
    width: 36.2% !important;
  }

  .non-input-field {
    position: relative;
    font-weight: 400;
    font-size: 12px;

    .wrapper {
      display: flex;
      margin-top: 2.2rem;
      margin-left: -1rem;
      align-items: center;

      // position of the checkbox related to 'Rent validated'
      .ui.checkbox {
        top: 0.6rem;
      }
    }
  }

  .row-mb {
    margin-bottom: 2.857rem !important;
  }

  .sub-header {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.429rem;
    display: flex;
    align-items: center;
    color: $blue-very-dark;
  }

  .btn-container {
    padding-left: 0 !important;

    .add-btn {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.286rem;

      svg {
        color: $white;
      }

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 0.8571rem;
        line-height: 1.357rem;
        text-align: center;
        text-transform: uppercase;
        color: $white;
        margin-left: 0.286rem;
      }
    }
  }

  .special-clause-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.8571rem;
    border-radius: 0.571rem;
    height: 3.429rem;

    &.disabled {
      background: $gray-lighter;
    }

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.429rem;
      color: $blue-very-dark;
    }
  }

  .clear-btn {
    display: flex;
    align-items: center;
    margin-top: 2.2857rem;
    cursor: pointer;

    svg {
      margin-right: 0.286rem;
      transform: scale(1.4);
    }
  }
}
