@import 'react-phone-input-2/lib/style.css';
@import 'react-toastify/dist/ReactToastify.css';
@import 'assets/styles/_colors.scss';
@import 'assets/styles/_reactDatePicker.scss';
@import 'assets/styles/_editorToHtml.scss';
@import 'assets/styles/_styles.scss';
@import 'assets/styles/_buttons.scss';

@import 'assets/styles/_toastify.scss';
@import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
@import 'react-calendar/dist/Calendar.css';

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html {
  @media (max-width: 77.1429rem) {
    font-size: 93.75%;
  }

  @media (max-width: 51.4286rem) {
    font-size: 87.5%;
  }

  ::-webkit-scrollbar {
    width: 0.7143rem;
    height: 0.3571rem;
  }

  ::-webkit-scrollbar-track {
    background: $gray-blackish;
  }

  ::-webkit-scrollbar-thumb {
    background: $orange;
    border-radius: 0.2143rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $orange-bright;
  }
}

body {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

html,
body {
  height: 100%;
  font-family: Urbanist;
}

/**
 * Scroll for Editor
 */
body ::-webkit-scrollbar {
  width: 0.2857rem;
  border-radius: 0.3571rem;
}

body ::-webkit-scrollbar-track {
  border-radius: 0.3571rem;
  background: $grayish-magenta-light;
}

body ::-webkit-scrollbar-thumb {
  background: $orange;
  border-radius: 0.2143rem;
}

body ::-webkit-scrollbar-thumb:hover {
  background: $orange-bright;
}

/**
 * Extra Rules
 */

body * {
  caret-color: $orange;
}

body *::selection {
  color: $blue-very-dark;
  background: $blue-light-grayish-v2;
}

button {
  cursor: pointer;
  border: none;
}

[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

div,
body,
input,
textarea,
button,
h1,
h2,
h3,
h4,
h5,
span,
a,
.ui.button,
.ui.input > input,
.ui.menu {
  font-family: 'Urbanist', 'Roboto', sans-serif;
  font-style: normal;
  margin-bottom: 0;
}

a,
u {
  text-decoration: none;
}

.ui.grid {
  margin: 0;
}

/** 
* This class ensures that the far left and far right containers in a grid
* align with the edge of the parent container
*/
.ui.grid.no-outer-padding {
  width: 100%;

  .row {
    padding-top: 0;
    padding-bottom: 0;
  }

  .row > div.column:first-of-type {
    padding-left: 0;
  }

  .row > div.column:last-of-type {
    padding-right: 0;
  }

  .row > .column > .field {
    margin-bottom: 0;
  }
}

.ui.grid > .row {
  padding-top: 0;
}

div.outlined.container {
  width: 100% !important;
  box-sizing: border-box;
  border-radius: 0.8571rem;

  .section-title {
    font-weight: 600;
    font-size: 1.286rem;
    line-height: 1.643rem;
    display: flex;

    .field.field-style {
      margin-left: 1.1429rem;
    }
  }
}

.ui.grid,
.row,
.column {
  &.outlined.container {
    width: 100% !important;
    box-sizing: border-box;
    border-radius: 0.8571rem;

    .section-title {
      font-weight: 600;
      font-size: 1.286rem;
      line-height: 1.643rem;
      display: flex;

      .field.field-style {
        margin-left: 1.1429rem;
      }
    }
  }
}

.grid {
  &.outlined.container {
    width: 100% !important;
    box-sizing: border-box;
    border-radius: 0.8571rem;

    .section-title {
      font-weight: 600;
      font-size: 1.286rem;
      line-height: 1.643rem;
      display: flex;

      .field.field-style {
        margin-left: 1.1429rem;
      }
    }
  }
}
